import cx from 'classnames';
import { Grid as SubGrid, useBreakpoint } from 'light-react-grid';

import { AnalyticsModule } from '~/ui/components/analytics';
import { type ArticleCard as ArticleCardType } from '~/v1/_types/ArticleCard';
import { type Idea } from '~/v1/_types/Idea';
import { ArticleType } from '~/v1/_types/globalTypes';
import { ArticleCard } from '~/v1/components/cards/article/article';
import { type RelatedArticle } from '~/v1/components/cards/article/article.interface';
import { mapArticleCard } from '~/v1/components/cards/article/article.utils';
import { EventCard } from '~/v1/components/cards/event/event';
import { mapEventCard } from '~/v1/components/cards/event/event.utils';
import { FeatureCard } from '~/v1/components/cards/feature/feature';
import { mapFeatureCard } from '~/v1/components/cards/feature/feature.utils';
import { IdeaCard } from '~/v1/components/cards/idea/idea';
import { mapIdeaCard } from '~/v1/components/cards/idea/idea.utils';
import { MMUFCard } from '~/v1/components/cards/mmuf/mmuf';
import { NewsCard } from '~/v1/components/cards/news/news';
import { mapNewsCard } from '~/v1/components/cards/news/news.utils';
import { OpinionCard } from '~/v1/components/cards/opinion/opinion';
import { mapOpinionCard } from '~/v1/components/cards/opinion/opinion.utils';
import { Column } from '~/v1/components/grid/column/column';
import { Grid } from '~/v1/components/grid/grid';
import { Quote } from '~/v1/components/quote/quote';
import { Breakpoint } from '~/v1/constants/breakpoint';
import { ContentType } from '~/v1/constants/contentType';

import styles from './related.module.scss';

interface RelatedGridModuleProps {
  content: Array<RelatedArticle | React.ReactNode>;
  label?: string;
  labelClassName?: string;
  gridClassName?: string;
}

export function RelatedGridModule({
  content,
  label,
  labelClassName,
  gridClassName,
}: RelatedGridModuleProps) {
  const bp = useBreakpoint();
  return (
    <Grid className={gridClassName ?? 'section'}>
      {label && (
        <Column sm={5} md={5} lg={5} offsetLeft={{ lg: 1 }}>
          <h3 className={cx(labelClassName ?? 'modulesTitleTypography', styles.title)}>{label}</h3>
        </Column>
      )}

      <Column sm={5} md={3} lg={3} offsetLeft={{ lg: 1 }}>
        <SubGrid>
          {content.map((contentItem, index) => {
            const isContent = isRelatedContent(contentItem);

            return bp == Breakpoint.SM ? (
              isContent ? (
                // render all cards on small breakpoint
                <Column
                  key={index}
                  sm={4}
                  className={styles.cardWrapper}
                  offsetLeft={{ sm: index % 2 === 0 ? 0 : 1 }}
                  offsetRight={{ sm: index % 2 === 0 ? 1 : 0 }}
                >
                  <div
                    className={cx(styles.cardInnerWrapper, {
                      [styles.cardInnerWrapperOdd]: index % 2 === 0,
                    })}
                  >
                    {renderItem(contentItem)}
                  </div>
                </Column>
              ) : (
                <Column key={index} className={styles.cardWrapper} sm={5}>
                  <div className="overflowLeft" style={{ marginTop: '2.4rem' }}>
                    {contentItem}
                  </div>
                </Column>
              )
            ) : index % 2 === 0 && isContent ? (
              // render even cards on left side for medium and > breakpoints
              <Column key={index} md={3} lg={3} className={styles.cardWrapper}>
                {renderItem(contentItem)}
              </Column>
            ) : null;
          })}
        </SubGrid>
      </Column>

      {/* render odd cards on right side for medium and > breakpoints */}
      {bp !== Breakpoint.SM && (
        <Column md={3} lg={4} offsetLeft={{ md: 1, lg: 1 }}>
          <SubGrid>
            {content.map((contentItem, index) => {
              const isArticle = isRelatedContent(contentItem);

              if (!isArticle) {
                return (
                  <Column key={index} className={styles.cardWrapperOdd} md={4} lg={4}>
                    {contentItem}
                  </Column>
                );
              }

              return index % 2 !== 0 && isArticle ? (
                <Column key={index} md={3} lg={3} className={styles.cardWrapperOdd}>
                  {renderItem(contentItem)}
                </Column>
              ) : null;
            })}
          </SubGrid>
        </Column>
      )}
    </Grid>
  );
}

function isRelatedContent(
  contentItem: RelatedArticle | React.ReactNode,
): contentItem is RelatedArticle {
  return !!contentItem && typeof contentItem === 'object' && '__typename' in contentItem;
}

function renderItem(contentItem: RelatedArticle) {
  switch (contentItem.__typename) {
    case ContentType.IDEA:
    case ContentType.NESTED_IDEA:
      return <IdeaCard {...mapIdeaCard(contentItem as Idea)} />;

    case ContentType.QUOTE:
      return <Quote quote={contentItem} isNarrow />;

    case ContentType.ARTICLE:
    case ContentType.NESTED_ARTICLE:
      return articleMapper(contentItem);

    case ContentType.EXTERNAL_EVENT:
      return <EventCard {...mapEventCard(contentItem)} />;

    case ContentType.GRANT_ACTIVITY_ARTICLE:
    case ContentType.NESTED_GRANT_ACTIVITY_ARTICLE:
    case ContentType.ANNUAL_REPORT:
    case ContentType.NESTED_ANNUAL_REPORT:
      return <ArticleCard {...mapArticleCard(contentItem)} className={styles.card} />;

    case ContentType.MMUF:
      return <MMUFCard {...contentItem} />;

    case ContentType.FEATURE:
      return <FeatureCard {...mapFeatureCard(contentItem)} />;
  }
}

function articleMapper(data: RelatedArticle) {
  const article = data as ArticleCardType;
  switch (article.type) {
    case ArticleType.GENERIC:
      return <ArticleCard {...mapArticleCard(article)} className={styles.card} />;
    case ArticleType.GRANTEE:
      return (
        <NewsCard
          {...mapNewsCard(article)}
          gtm={{ module: AnalyticsModule.RELATED }}
          className={styles.card}
        />
      );
    case ArticleType.MELLON_IN_THE_NEWS:
      return (
        <NewsCard
          {...mapNewsCard(article)}
          gtm={{ module: AnalyticsModule.RELATED }}
          className={styles.card}
        />
      );
    case ArticleType.PAST_EVENT:
      return <EventCard {...mapEventCard(article)} className={styles.card} />;
    case ArticleType.REPORT:
      return <ArticleCard {...mapArticleCard(article)} className={styles.card} />;
    case ArticleType.VOICES:
      return <OpinionCard {...mapOpinionCard(article)} className={styles.card} />;
  }
}
