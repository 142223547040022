import NextLink from 'next/link';

import cx from 'classnames';

import { AnalyticsCard, AnalyticsModule, Event, trackEvent } from '~/ui/components/analytics';
import { type MMUFCard as MMUFCardType } from '~/v1/_types/MMUFCard';
import { Icon } from '~/v1/components/icons//icon';
import { IconType } from '~/v1/components/icons/icon.interfaces';
import { Image } from '~/v1/components/image/image';
import { ImageBreakpoint } from '~/v1/components/image/image.interface';
import { Route } from '~/v1/constants/route';
import { TEST_ID } from '~/v1/constants/testId';

import styles from './mmuf.module.scss';

export interface MMUFCardProps extends MMUFCardType {
  className?: string;
}

export const MMUFCard: React.FC<MMUFCardProps> = ({
  className,
  title,
  introDescription,
  heroImage,
}) => {
  const CTA_TEXT = 'Learn about the MMUF';

  return (
    <NextLink
      href={Route.MMUF}
      className={cx(styles.landingCard, className)}
      onClick={() =>
        trackEvent(Event.CARD_CLICK, {
          cardModule: AnalyticsModule.RELATED,
          cardType: AnalyticsCard.MMUF,
          cardCTA: CTA_TEXT,
          cardTitle: title,
        })
      }
      data-test-id={TEST_ID.CARD.MMUF}
    >
      {heroImage && (
        <Image
          aspectRatio="3/2"
          colSpan={{ [ImageBreakpoint.SM]: 4, [ImageBreakpoint.MD]: 3 }}
          className={cx(styles.logoWrapper, styles.image)}
          src={heroImage.src}
          alt={heroImage.title}
        />
      )}

      <div className={styles.cardContent}>
        <div>
          <div className={cx('bodyLargeTypography', styles.title)}>{title}</div>
          <div className={cx('paragraphTypography', styles.description)}>{introDescription}</div>
        </div>

        <div className={cx('linkTypography', styles.link)}>
          <span>{CTA_TEXT}</span>
          &nbsp;
          <Icon type={IconType.ExpandingArrowRight} />
        </div>
      </div>
    </NextLink>
  );
};
