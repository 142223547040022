import cx from 'classnames';
import { ReactSVG } from 'react-svg';

import { AnalyticsCard, AnalyticsContent, AnalyticsModule, Event } from '~/ui/components/analytics';
import { FullBleedCTA, FullBleedCTATrigger } from '~/v1/components/fullBleedCta/fullBleedCta';
import { ImageBreakpoint } from '~/v1/components/image/image.interface';
import { Link } from '~/v1/components/link/link';
import { Route } from '~/v1/constants/route';

import { type ArticleCardType } from './article.interface';
import styles from './article.module.scss';
import { Image } from '../../image/image';

export interface ArticleCardProps extends ArticleCardType {
  className?: string;
}

export const ArticleCard: React.FC<ArticleCardProps> = ({
  image,
  eyebrow,
  title,
  ctaText,
  ctaUrl,
  className,
  logo,
}) => {
  return (
    <FullBleedCTA label={title} className={cx(styles.root, className)}>
      {image && (
        <Image
          className={styles.image}
          src={image.src}
          alt={image.title}
          colSpan={{ [ImageBreakpoint.SM]: 4, [ImageBreakpoint.MD]: 3 }}
        />
      )}
      {logo && (
        <div>
          {logo.src.endsWith('.svg') ? (
            <ReactSVG src={logo.src} className={styles.logo} title={logo.title} />
          ) : (
            <Image aspectRatio="4/1" className={styles.logo} src={logo.src} alt={logo.title} />
          )}
        </div>
      )}
      <div className={styles.content}>
        {eyebrow && <div className={cx('bodySmallTypography', styles.eyebrow)}>{eyebrow}</div>}
        <div className={cx('bodyLargeTypography truncate-3', styles.title)}>{title}</div>

        <FullBleedCTATrigger>
          <Link
            href={ctaUrl}
            event={{
              name: Event.CARD_CLICK,
              data: {
                cardModule: AnalyticsModule.RELATED,
                cardType: AnalyticsCard.ARTICLE,
                cardContentType: ctaUrl.includes(Route.GRANT_STORY)
                  ? AnalyticsContent.GRANT_STORY
                  : AnalyticsContent.ARTICLE,
                cardCTA: ctaText,
                cardTitle: title,
              },
            }}
            withIcon
          >
            {ctaText}
          </Link>
        </FullBleedCTATrigger>
      </div>
    </FullBleedCTA>
  );
};
